// src/components/Home/FaqItem.js
import React, { useState } from 'react';
import styles from './faqs.module.css';
import { MdKeyboardArrowDown } from "react-icons/md";

const FaqItem = ({ question, answer, isOpen = false }) => {
    const [isOpenState, setIsOpenState] = useState(isOpen);

    const toggleOpen = () => {
        setIsOpenState(!isOpenState);
    };

    return (
        <div className={styles.faqItem}>
            <h3 className={`${styles.question} flex flex-row justify-between`} onClick={toggleOpen}>
                {question}
                <MdKeyboardArrowDown className="text-2xl"/>
            </h3>
            {isOpenState && <p className={`${styles.answer} mt-5 ml-5 font-normal`}>{answer}</p>}
        </div>
);
};

export default FaqItem;

import React, { useLayoutEffect } from 'react';
import styles from "./benefits.module.css";
import Image from "next/image";

const Benefits = () => {
    return (
        <div id="benefits" className={`${styles.benefits} flex flex-col mt-20 gap-12`}>
            <Image src="/images/line.svg" alt="test" width={15} height={150} className="mx-auto"/>
            <div className={`w-8/12 mx-auto ${styles.benefitsContent}`}>
                <h2 className="text-6xl text-center mb-10 font-normal leading-[90px]">
                    Beneficios<br/>de trabajar con Desarrollo Web Profesional.
                </h2>
                <p className="text-2xl mx-auto text-center mb-5">
                    Beneficios tan buenos que nunca necesitarás ir a ningún otro lugar para tus desarrollos. En serio.
                </p>
            </div>
            <div className={`${styles.contentBenefits} flex flex-row justify-between gap-10 mt-5`}>
                <div className={`${styles.benefitCard} p-5`}>
                    <span
                        className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full mx-auto`}>
                        <video width="100%" autoPlay loop muted>
                            <source src="/animation/time.webm" type="video/webm"/>
                            Your browser does not support the video tag.
                        </video>
                    </span>
                    <h3 className="font-bold mb-5 text-center">Pagas sólo lo que obtienes</h3>
                    <p className="text-center">
                        Con nuestro paquete de horas, tienes la flexibilidad de utilizar el tiempo como mejor te convenga, asegurando que siempre haya un experto en desarrollo web profesional disponible para resolver tus dudas y realizar ajustes según tus requerimientos. Maximiza tu inversión con un servicio de desarrollo web profesional que se adapta a ti.
                    </p>
                </div>
                <div className={`${styles.benefitCard} p-5`}>
                    <span
                        className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full mx-auto`}>
                        <video width="100%" autoPlay loop muted>
                            <source src="/animation/task.webm" type="video/webm"/>
                            Your browser does not support the video tag.
                        </video>
                    </span>
                    <h3 className="font-bold mb-5 text-center">Gestión de tareas y procesos</h3>
                    <p className="text-center">
                        Este sistema garantiza una mayor transparencia y organización, facilitando la comunicación y la colaboración entre todas las partes involucradas. Con un gestor de tareas, puedes supervisar el progreso en tiempo real, establecer prioridades claras y asegurarte de que cada etapa del proyecto se complete a tiempo.
                    </p>
                </div>
                <div className={`${styles.benefitCard} p-5`}>
                    <span
                        className={`${styles.cardIcon} flex flex-column justify-center items-center mb-5 rounded-full mx-auto`}>
                        <video width="100%" autoPlay loop muted>
                            <source src="/animation/rocket.webm" type="video/webm"/>
                            Your browser does not support the video tag.
                        </video>
                    </span>
                    <h3 className="font-bold mb-5 text-center">Compromiso y colaboración</h3>
                    <p className="text-center">
                        Comunicación fluida y una comprensión profunda de tus necesidades, permitiendo soluciones personalizadas y efectivas. Con un compromiso continuo, el desarrollador puede anticipar mejoras proactivas, timizando tu sitio web constantemente y asegurando un rendimiento superior.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Benefits;

import React, { useLayoutEffect, useState } from "react";
import styles from "./slide.module.css";
import Link from "next/link";
import Image from "next/image";

export default function Slide() {
  const [isOpen, setIsOpen] = useState(false);
  const scrollToSection = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
        const targetPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const startPosition = window.pageYOffset;
        const distance = targetPosition - startPosition;
        const duration = 1000; // Duración de la animación en milisegundos
        let start = null;

        const step = (timestamp) => {
            if (!start) start = timestamp;
            const progress = timestamp - start;
            const progressPercentage = Math.min(progress / duration, 1);
            const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            const easedProgress = easeInOutQuad(progressPercentage);
            window.scrollTo(0, startPosition + distance * easedProgress);
            if (progress < duration) {
                window.requestAnimationFrame(step);
            }
        };

        window.requestAnimationFrame(step);
    }
  };

  return (
    <div className={`${styles.slide} flex flex-col justify-center pb-10`}>
      <div className="mx-auto flex flex-col pt-5">
        <div
          className={`${styles.slideContent} flex flex-col md:flex-row gap-10 md:gap-20`}
        >
          <div className={`${styles.slideText} flex flex-col`}>
            <div className="bg-white mb-10 mx-auto text-center rounded-lg text-sm px-5 py-1">
              Más de 15 años ayudando a empresas a mejorar su presencia online
            </div>
            <h1 className="mb-10 text-center mx-auto">
              <span>Desarrollo web Profesional</span> estratégico: Impulsando tu marca al{" "}
              <span>siguiente nivel</span>
            </h1>
            <div
              className={`${styles.slideLinks} flex flex-col md:flex-row gap-5 md:gap-10 mt-10 mx-auto`}
            >
              <Link
                href="#precios"
                className="flex flex-row items-center font-normal"
                onClick={(e) => {
                  scrollToSection(e, "pricing");
                  setIsOpen(false);
                }}
              >
                Ver planes
              </Link>
              <Link
                href="/contacto"
                className={`${styles.slideContactBtn} flex flex-row items-center font-normal`}
              >
                Contáctanos
              </Link>
            </div>
          </div>
        </div>

        <section className="homeTec">
          <div className="flex flex-col md:flex-row justify-center text-white">
            <div className="flex items-center mb-4 md:mb-0">
              <Link href="https://wordpress.com" target="_blank">
                <Image
                  src="/images/services/wordpress.webp"
                  width={180}
                  height={113}
                  alt="Desarrollo Web: Wordpress"
                  title="Wordpress"
                />
              </Link>
              <div className="separator hidden md:block"></div>
            </div>
            <div className="flex items-center mb-4 md:mb-0">
              <Link href="https://prestashop.com" target="_blank">
                <Image
                  src="/images/services/prestashop.webp"
                  width={180}
                  height={113}
                  alt="Desarrollo Web: Prestashop"
                  title="Prestashop"
                />
              </Link>
              <div className="separator hidden md:block"></div>
            </div>
            <div className="flex items-center mb-4 md:mb-0">
              <Link href="https://laravel.com" target="_blank">
                <Image
                  src="/images/services/laravel.webp"
                  width={180}
                  height={113}
                  alt="Desarrollo Web: Laravel"
                  title="Laravel"
                />
              </Link>
              <div className="separator hidden md:block"></div>
            </div>
            <div className="flex items-center mb-4 md:mb-0">
              <Link href="https://nextjs.org/" target="_blank">
                <Image
                  src="/images/services/nextjs.webp"
                  width={180}
                  height={113}
                  alt="Desarrollo Web: Nextjs"
                  title="Nextjs"
                />
              </Link>
              <div className="separator hidden md:block"></div>
            </div>
            <div className="flex items-center">
              <Link href="https://shopify.com" target="_blank">
                <Image
                  src="/images/services/shopify.webp"
                  width={180}
                  height={113}
                  alt="Desarrollo Web: Shopify"
                  title="Shopify"
                />
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

import React, { useLayoutEffect } from 'react';
import styles from './testimonials.module.css';
import Image from 'next/image'
import Link from 'next/link'

const Testimonials = () => {
return (
    <div className={`${styles.testimonials} flex flex-col mt-20 gap-12`}>
        <Image src="/images/line.svg" alt="test" width={15} height={150} className="mx-auto" />
        <div className="w-8/12 mx-auto">
            <h2 className="text-7xl text-center mb-10 font-normal leading-[90px]">
                Mucho mejor<br /> de lo que imaginas.
            </h2>
            <p className="text-2xl mx-auto text-center mb-5">
            Olvídate de freelancers poco confiables y agencias costosas. Con nuestros servicios de desarrollo web profesional, te ofrecemos desarrollos ilimitados, con entregas tan rápidas que te dejarán boquiabierto.
            </p>
            <Link href="/#pricing" className={`${styles.btnTestimonials} w-[200px] mx-auto text-center mt-10`}>Ver planes</Link>
        </div>
        <div className="flex flex-col md:flex-row mt-2 gap-12 items-center">
            <div className={`${styles.testimonial} flex flex-col align-middle bg-white p-10`}>
                <h2 className="text-3xl">“Las nuevas características y mejoras han mejorado enormemente la experiencia de nuestros clientes.”</h2>
                <div className="flex flex-row justify-around items-end w-full mt-10">
                    <div className={`${styles.cardDestimonials} w-[60%]`}>
                        <b>Nina Balmes</b><br />
                        <Link href="https://larcabarcelona.com" target="_blank">larcabarcelona.com</Link>
                    </div>
                    <div className={`${styles.cardDestimonials} w-[40%]`}>
                        <Image
                            src="/images/testimonios/larca-nina.webp"
                            alt="Kevin O'Leary"
                            width={305}
                            height={102}
                            className="rounded-[15px]"/>
                    </div>
                </div>
            </div>
            <div className={`${styles.testimonialsDescription}`}>
                <h3 className="mb-2">Totalmente asíncrono</h3>

                ¿No te gustan las reuniones? A nosotros tampoco; tanto que las hemos prohibido por completo.

                <h3 className="mt-5 mb-2">Gestiona con Trello</h3>

                Organizamos tu tablero de desarrollo con Trello. Visualiza fácilmente tareas activas, en cola y completadas.

                <h3 className="mt-5 mb-2">Invita a tu equipo</h3>

                Invita a todo tu equipo a Desarrollo Web Profesional para que todos puedan enviar solicitudes y rastrear tu progreso.
            </div>
        </div>
    </div>
);
};

export default Testimonials;
